<template>
  <div style="margin: 20px"
       v-loading="loading">
    <el-row>
      <el-col :span="12">
        <label>{{`Полнота предоставления данных мониторинга ${completeness.completed || 0}/${completeness.should_be_entered || 0}`}}</label>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <label>Период закроется {{ getPeriodEnd() }}</label>
      </el-col>
    </el-row>
    <el-progress :percentage="calcPercent(completeness.completed, completeness.should_be_entered)" color="#67c23a"></el-progress>
    <br>
    <storage-table
      :list="tableData"
      :total="total"
      name="storage"
      :columns="columns"
      :need-id="false"
      :need-actions="true"
      @storage-edit="storageEdit"
      @storage-view="showModalView"
      @fetch-data="fetchData"
    />
    <el-dialog top="5vh" :visible.sync="dialogVisibleEdit">
      <storage-detail v-if="dialogVisibleEdit" :id="storageId" @updated="fetchData"/>
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="dialogVisibleView">
      <storage-view-modal v-if="dialogVisibleView" :storage-id="storageId"/>
    </el-dialog>
  </div>
</template>

<script>
import StorageTable from '@/components/StorageTable'
import StorageDetail from './StorageDetail'
import StorageViewModal from '@/views/storage/StorageViewModal'
import { getstorageKpCompletness, getStorageKpList } from '@/api/api'
export default {
  components: {
    StorageTable,
    StorageDetail,
    StorageViewModal
  },
  data () {
    return {
      iogvs: [],
      tableData: [],
      params: undefined,
      total: 0,
      loading: false,
      dialogVisibleEdit: false,
      dialogVisibleView: false,
      storageId: null,
      periodEnd: null,
      completeness: {
        completed: null,
        percent: null,
        should_be_entered: null
      },
      actions: [
        {
          name: 'edit'
        },
        {
          name: 'view'
        }
      ]
    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'pki_index',
          label: 'Индекс КПР',
          width: '100px'
        },
        {
          field: 'name',
          label: 'Название'
        },
        {
          field: 'formula',
          label: 'Расчёт',
          type: 'formula',
          filterable: false,
          width: '200px'
        },
        {
          field: 'result',
          label: 'Результат',
          filterable: false,
          width: '100px'
        },
        {
          field: 'file',
          label: 'Файл',
          width: '100px',
          items: [
            {
              id: 'exist',
              title: 'Приложен'
            },
            {
              id: 'not_exist',
              title: 'Не приложен'
            },
            {
              id: 'exist_pdf',
              title: 'Приложен PDF'
            },
            {
              id: 'not_exist_pdf',
              title: 'Не приложен PDF'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.getStorageCompletness()
  },
  methods: {
    getPeriodEnd () {
      const periodEnd = new Date(this.periodEnd)
      return periodEnd.toLocaleString()
    },
    getStorageCompletness () {
      getstorageKpCompletness().then((response) => {
        this.completeness = response.data.completeness
      })
    },
    calcPercent (a, b) {
      if (a) {
        return Math.round(((a / b) * 100))
      }
      return 0
    },
    fetchData (params) {
      this.loading = true
      this.dialogVisibleEdit = false
      if (params) {
        this.params = params
      }
      getStorageKpList(this.params).then((response) => {
        this.tableData = response.data.data
        this.total = response.data.meta.total
        this.periodEnd = response.data.meta.period.date_stop
      }).finally(() => {
        this.loading = false
      })
    },
    storageEdit (row) {
      this.$router.push({ name: '/kp-storage/overall', query: { pki_id: row.pki_id, period_id: row.period_id } })
    },
    showModalEdit (id) {
      this.storageId = id
      this.dialogVisibleEdit = true
    },
    showModalView (id) {
      this.storageId = id
      this.dialogVisibleView = true
    }
  }
}
</script>

<style scoped>

</style>
